import React, { useEffect, useMemo, useState } from "react";
import { theOpenNetworkAuth, tonPreconnect } from "../functions";

const TonConnectBtn = () => {

    const [wallet, setWallet] = useState("");
    const [pending, Pending] = useState(false);

    /* useMemo(() => {
        tonPreconnect()
    }, []) */

    const updateWallet = (wallet: any) => {
         const account = wallet?.account;
         if (account) {
            setWallet(account)
         }
    }

    const connectWallet = async () => {
        if (pending) return;
        Pending(true);
        setWallet(await theOpenNetworkAuth () || "");
        Pending(false);
    }

    return (
      <div className="btn connectBtn" onClick={connectWallet}>
           <p>
             {wallet || "Connect wallet"}
           </p>
      </div>
    );
  };
  
  export default TonConnectBtn;