import React, { useEffect, useMemo, useState } from "react";
import { theOpenNetworkAuth, tonPreconnect } from "../functions";
import { useTonConnect } from "../hooks/useTonConnect";

const TonInfo = () => {
    const {connected, wallet} = useTonConnect()

    return (
      <div className="btn connectBtn">
           <p>
             Connected: {connected ? "yes" :"no"}
           </p>
           <p>
             Wallet: {wallet}
           </p>
      </div>
    );
  };
  
export default TonInfo;