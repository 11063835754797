import { useEffect, useState } from "react";
import { Address, beginCell, Cell, fromNano, toNano, OpenedContract } from "@ton/core";
import { CollectionMintMsg, SimpleNFTMaster } from "../contracts/SimpleNFTMaster/tact_SimpleNFTMaster";
import { useAsyncInitialize } from "./useAsyncInitialize";
import { useTonClient } from "./useTonCLient";
import { collectionToWatch, collectionVacuumContract, masterContract, masterTonContract, OFFCHAIN_CONTENT_PREFIX } from "../config";
import { useTonConnect } from "./useTonConnect";
import { SimpleNftCollection } from "../contracts/SimpleNFTCollection/tact_SimpleNftCollection";
import { VacuumNft } from "../contracts/VacuumNft/tact_VacuumNft";

const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time))

export function useCollectionContract() {
    const { client } = useTonClient();
    const {wallet, sender} = useTonConnect();
    const [balance, setBalance] = useState<string | null>();

    const customCollection = (address: string) => {
        return useAsyncInitialize(async() => {
            if (!client || !wallet) return;

            console.log("Address: ", address)
    
            const contract: any = SimpleNftCollection.fromAddress(Address.parse(address));
    
            return client.open(contract) as OpenedContract<SimpleNftCollection>
        }, [client, wallet])
    }

    return {
        balance: balance,
        deploy: async (dataFile?: string, itemFile?: string, price?: number, 
            mintLimit?: number, royaltyPercent?: number) => {
            if (!sender || !sender.address) return null;

            const string_first = dataFile || "https://tg.hidrona.com/ton_img/collection.json";
            const string_img = itemFile || "https://s.getgems.io/nft/c/66db26464e143f33e592521a/0/meta.json";
            const content = beginCell().storeInt(OFFCHAIN_CONTENT_PREFIX, 8).storeStringRefTail(string_first).endCell();
            const master = Address.parse(masterContract);
            const ownerAddress = sender.address;
            console.log("Address: ")
            const newContractAddress: any = await SimpleNftCollection.fromInit(
                ownerAddress,
                master,
                content,
                string_img,
                price ? toNano(String(price)) : toNano("0.5"),
                mintLimit ? BigInt(mintLimit) : 0n,
                {
                    $$type: "RoyaltyParams",
                    numerator: royaltyPercent ? BigInt(royaltyPercent) : 0n, // 350n = 35%
                    denominator: 1000n,
                    destination: ownerAddress,
                }
            );
            console.log(newContractAddress);
            console.log(newContractAddress.address.toString());
            console.log(Address.isAddress(ownerAddress));
            console.log(Address.isAddress(newContractAddress.address.toString()));
            console.log(ownerAddress);
            const newContract = client?.open(newContractAddress) as OpenedContract<SimpleNftCollection>;
            await newContract.send(sender, {
                value: toNano("0.1")
            }, {
                $$type: "Deploy",
                queryId: 0n
            });
            return newContractAddress.address.toString();
        },
        getMasterData: (address: string) => {
            return customCollection(address)?.getGetMasterData();
        },
        getNftAddressByIndex: (address: string, index: number) => {
            return customCollection(address)?.getGetNftAddressByIndex(BigInt(index))
        },
        getNftContent: (address: string, index: number) => {
            return customCollection(address)?.getGetNftContent(BigInt(index), new Cell({}))
        },
        mint: async (address: string) => {
            return new Promise((resolve, reject) => {
                if (!wallet) {
                    resolve(false);
                    return;
                }

                const contract = SimpleNftCollection.fromAddress(Address.parse(address));
                const opened = client?.open(contract) as OpenedContract<SimpleNftCollection>
                console.log("Owner address: ", wallet, Address.parse(wallet))

                opened?.send(sender, {
                    value: toNano("0.3")
                }, "Mint").then((result) => {
                    console.log("Result: ", result);
                    resolve(true)
                }).catch((e) => {
                    console.log("Error: ", e);
                    resolve(false)
                })
            })
        }
    }
}