import React, { useEffect, useMemo, useState } from "react";
import { theOpenNetworkAuth, tonPreconnect } from "../functions";
import { useTonConnectUI } from "@tonconnect/ui-react";


const WithdrawBtn = () => {

    const [pending, Pending] = useState(false);
    const [tonConnectUI, setOptions] = useTonConnectUI();


    const mintCollection = async () => {

        if (pending) return;
        Pending(true);
        // await withdraw();
        Pending(false);
    }

    return (
      <div className="btn connectBtn" onClick={mintCollection}>
           <p>
             {"Withdraw assets"}
           </p>
      </div>
    );
  };
  
  export default WithdrawBtn;

// const [tonConnectUI, setOptions] = useTonConnectUI();