import React, { useEffect, useState } from "react";

const NftMintForm = () => {
  return (
    <div className="form">
      <p>Collection address: </p>
      <input type="text" />
      <p>Mint limit: </p>
      <input type="text" />
      <p>Metadata: </p>
      <input type="text" />
    </div>
  );
};

export default NftMintForm;