import React, { useEffect, useMemo, useState } from "react";
import { theOpenNetworkAuth, tonPreconnect } from "../functions";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { useCollectionContract } from "../hooks/useCollectionContract";
import { Address } from "@ton/core";

export interface MintCollectionData {
    dataFile?: string, 
    itemFile?: string, 
    price?: number, 
    mintLimit?: number, 
    royaltyPercent?: number
}
const MintCollectionBtn = (props: {data: MintCollectionData}) => {

    const [pending, Pending] = useState(false);
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const { deploy } = useCollectionContract();

    const mintAction = () => {
        Pending(true)
        deploy(
            props.data.dataFile,
            props.data.itemFile,
            props.data.price,
            props.data.mintLimit,
            props.data.royaltyPercent
        ).then((address) => {
            alert(address);
            Pending(false)
        })
    }

    return (
      <>
        <div className="btn" onClick={mintAction}>
            Create collection
        </div>
      </>
    );
  };
  
  export default MintCollectionBtn;

// const [tonConnectUI, setOptions] = useTonConnectUI();