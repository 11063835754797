import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CHAIN, TonConnectButton, TonConnectUIProvider, useTonConnectUI } from "@tonconnect/ui-react";
import Section from '../components/section';
import CollectionCreateForm from '../components/collectionCreateForm';
import NftMintForm from '../components/nftMintForm';
import TonConnectBtn from '../components/connectBtn';
import { tonMainfestUrl } from '../config';
import { useTonConnect } from '../hooks/useTonConnect';
import TonInfo from '../components/info';
import WithdrawBtn from '../components/withdrawBtn';
import MintItemBtn from '../components/mintItemBtn';
import { useParams } from 'react-router-dom';

const SingleCollectionPage = () => {

  const {network} = useTonConnect()
  const { collection } = useParams();

  return (
    <div className="app">
         <div className="snftBody">
            <TonConnectButton />
            <TonConnectBtn />
            <div className="snftHeading">
                {`Simple NFT - collection: ${collection}`}
                <p>
                  {network === CHAIN.TESTNET ? "testnet" : "mainnet"}
                </p>
            </div>
            <TonInfo />
            <div className="snftContent">
            <Section>
              <MintItemBtn />
            </Section>
            </div>
         </div>
    </div>
  );
};

export default SingleCollectionPage