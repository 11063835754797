import React, { useEffect, useState } from "react";
import MintCollectionBtn, { MintCollectionData } from "./mintCollectionBtn";
import { useParams } from "react-router-dom";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { useTonConnect } from "../hooks/useTonConnect";

const CollectionCreateForm = () => {

  const { collection } = useParams();
  
  const [formData, setFormData] = useState<MintCollectionData>({})

  const updateFormData = (param: keyof MintCollectionData) => {
      return (event: any) => {
          const updatedData = new Map();
          for( let key in formData) {
            updatedData.set(key, key === param ? event.target?.value || "" : formData[key])
          }
          setFormData(Object.fromEntries(updatedData))
      }
  }
  
  return (
    <div className="form">
      <p>Collection data url: </p>
      <input type="text" value={formData.dataFile} onChange={updateFormData("dataFile")} />
      <p>Item data url: </p>
      <input type="text" value={formData.itemFile} onChange={updateFormData("itemFile")} />
      <p>Mint limit: </p>
      <input type="number" value={formData.mintLimit} onChange={updateFormData("mintLimit")} />
      <p>Price in TON: </p>
      <input type="text" value={formData.price} onChange={updateFormData("price")} />
      <p>Royalty %: </p>
      <input type="text" value={formData.royaltyPercent} onChange={updateFormData("royaltyPercent")} />
      <MintCollectionBtn data={formData} />
    </div>
  );
};

export default CollectionCreateForm;
